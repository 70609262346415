import { useContext, useState, useEffect } from "react"
import ThemeContext from "../../context/ThemeContext"
import Layout from "../../components/layout"
import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../../components/seo"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import NullIf from "../../services/NullIf"
import t from '../../services/GetTranslationValue'
import r from '../../services/Router'
import MainSearch from "../../components/main-search/main-search"
import GameCards from "../../components/game-cards/game-cards"
import { getFilterTranslationsByName } from "../../services/preset-filters/get-all-filters"
import styles from "./item.module.scss"
import Img from "gatsby-image"
import Rating from "@material-ui/lab/Rating"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import localize from "../../components/localize"
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, IconButton, } from "@material-ui/core"
import h from "../../services/GenericHelpers"


const Content = ({ casinos, translations, locale, filters, gameProvider, games }) => {
  casinos = casinos
    .filter( x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.gameProviders))
    .filter(x => x.gameProviders.some(y => y && y.name === gameProvider.name))
    .sort((a, b) => {
      if (h.casinoAverageRating(b) > h.casinoAverageRating(a))
        return 1;
      if (h.casinoAverageRating(b) < h.casinoAverageRating(a))
        return -1;

      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })

  return (
    <div>
      <MainSearch headerFilters={filters} headerFilterParam={gameProvider.name} locale={locale} bonuses={casinos} />
      <GameCards games={games} provider={gameProvider.name} translations={translations} locale={locale} />
    </div>
  )
}

const Details = ({ translations, locale, gameProvider }) => {

  const [showAllPartners, setShowAllPartners] = useState(false);
  const [partnersShow, setPartnersShow] = useState(false);
  const [partnersHeight, setPartnersHeight] = useState();

  const gameTypes = [
    ["slots", ''],
    ["poker", 'hasVideoPoker'],
    ["keno", 'hasKeno'],
    ["virtualSports", ''], // skip
    ["baccarat", 'hasBaccarat'],
    ["roulette", 'hasRoulette'],
    ["bingo", 'hasBingo'],
    ["craps", 'hasCraps'], // skip
    ["blackJack", 'hasBlackjack'],
    ["videoPoker", 'hasVideoPoker'],
    ["liveGames", 'liveCasino'],
    ["progressiveJackpots", ''], // skip
    ["others", ''], // skip
    ["fixedOdds", ''], // skip
  ];

  const togglePartners = () => {
    setShowAllPartners(!showAllPartners);
  }

  useEffect(() => {
    gameProvider.partners.length > 0 && setPartnersHeight(document.querySelector('#partners-collapse .MuiCollapse-wrapper').offsetHeight);
    if (partnersHeight > 120) {
      setPartnersShow(true);
    }
  }, [partnersHeight]);

  const getItemIcons = (gameProvider, x) => {
    return [gameProvider[x[0]] ?
      <img alt={t.get(translations, `game-provider-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)} src={`/../../../icons/game-provider/normal/${x[0]}.svg`} />
      :
      <img alt={t.get(translations, `game-provider-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)} src={`/../../../icons/game-provider/disabled/${x[0]}.svg`} />
    ,
    <span className={`${gameProvider[x[0]] ? "" : styles.notAvailable}`}>
                  {t.get(translations, `game-provider-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}
                </span>]
  }

  return (
    <section className={`${styles.detailsContainer}`}>
      <div className={`${styles.info}`}>
        <div className={`${styles.logoContainer}`}>
          {gameProvider.largeIcon && gameProvider.largeIcon.asset && <Img
            alt={gameProvider.name}
            fluid={gameProvider.largeIcon.asset.fluid}
            className={`${styles.logo}`}
          >
            {" "}
            {gameProvider.name}{" "}
          </Img>}
        </div>
        <div className={`${styles.infoContainer}`}>
          <div className={`${styles.nameContainer}`}>
            <p className={`${styles.name}`}>{gameProvider.name}</p>
            <p className={`${styles.rating}`}>
              <Rating
                readOnly={true}
                name="half-rating"
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                size="small"
                value={0}
                precision={0.5}
              />
            </p>
          </div>
          <div className={`${styles.rowContainer}`}>
            <p className={`${styles.infoRow}`}>
              <label>{t.get(translations, 'website')}: </label>
              <span>
                <a
                  target={`_blank`}
                  rel={`${(gameProvider.url && gameProvider.url.includes('hacksawgaming')) ? '' : 'noopener noreferrer nofollow'}`}
                  href={`//${gameProvider.url.replace(/https:\/\//, "").replace(/www./, "")}`}
                >
                  {gameProvider.url.replace(/https:\/\//, "").replace(/www./, "")}
                </a>
              </span>
            </p>
            <p className={`${styles.infoRow}`}>
              <label>{t.get(translations, 'game-provider-details-headquarters')}: </label><span>{gameProvider.headquarters}</span>
            </p>
            <p className={`${styles.infoRow}`}>
              <label>{t.get(translations, 'game-provider-details-company')}: </label><span>{gameProvider.company}</span>
            </p>
          </div>
        </div>
        <div className={`${styles.platforms}`}>
          <div className={`${styles.platform}`}>
            {gameProvider.desktop ?
              <img alt={t.get(translations, 'game-provider-details-desktop')} src={`/../../../icons/game-provider/normal/pc.svg`} />
              :
              <img  alt={t.get(translations, 'game-provider-details-desktop')}src={`/../../../icons/game-provider/disabled/pc.svg`} />
            }
            <span className={`${gameProvider.desktop ? "" : styles.notAvailable}`}>
              {t.get(translations, 'game-provider-details-desktop')}
            </span>
          </div>
          <div className={`${styles.platform}`}>
            {gameProvider.mobile ?
              <img alt={t.get(translations, 'game-provider-details-mobile')} src={`/../../../icons/game-provider/normal/mobile.svg`} />
              :
              <img alt={t.get(translations, 'game-provider-details-mobile')} src={`/../../../icons/game-provider/disabled/mobile.svg`} />
            }
            <span className={`${gameProvider.mobile ? "" : styles.notAvailable}`}>
              {t.get(translations, 'game-provider-details-mobile')}
            </span>
          </div>
          <div className={`${styles.platform}`}>
            {gameProvider.vr ?
              <img alt={t.get(translations, 'game-provider-details-vr')} src={`/../../../icons/game-provider/normal/headset.svg`} />
              :
              <img alt={t.get(translations, 'game-provider-details-vr')} src={`/../../../icons/game-provider/disabled/headset.svg`} />
            }
            <span className={`${gameProvider.vr ? "" : styles.notAvailable}`}>
              {t.get(translations, 'game-provider-details-vr')}
            </span>
          </div>
          <div className={`${styles.platform}`}>
            {gameProvider.socialGames ?
              <img alt={t.get(translations, 'game-provider-details-social-games')} src={`/../../../icons/game-provider/normal/chat.svg`} />
              :
              <img alt={t.get(translations, 'game-provider-details-social-games')} src={`/../../../icons/game-provider/disabled/chat.svg`} />
            }
            <span className={`${gameProvider.socialGames ? "" : styles.notAvailable}`}>
              {t.get(translations, 'game-provider-details-social-games')}
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles.games}`}>
        <p className={`${styles.title}`}>{t.get(translations, 'game-provider-details-game-types')}</p>
        <div className={`${styles.gamesParent}`}>
          {gameTypes.map(x =>
            <div className={`${styles.game}`}>
              {NullIf.isNotNullOrEmpty(x[1]) ?
                <Link to={r.get(locale, translations, x[1], '')}>
                  {getItemIcons(gameProvider, x)}
                </Link>
              : getItemIcons(gameProvider, x)
              }

            </div>
          )}
        </div>
      </div>
      <div className={`${styles.games} ${styles.partners}`}>
        <p className={`${styles.title}`}>{t.get(translations, 'game-provider-details-partners')}</p>
        <div id="partners-collapse" className={`${styles.gamesParent}`}>
          <Collapse in={showAllPartners} collapsedHeight={100} timeout={'auto'}>
            {gameProvider.partners && gameProvider.partners.map(x =>
              <span className={`${styles.partnersItem}`}>
                <a
                  key={x.name}
                  href={'/'+ r.get(locale, translations, 'gameProvider', x.name)}
                >
                  {x.largeIcon && x.largeIcon.asset && (
                    <Img fluid={x.largeIcon.asset.fluid} alt={x.name} />
                  )}
                </a>
              </span>
            )}
          </Collapse>
          {partnersShow &&
            <IconButton
              edge="start"
              onClick={togglePartners}
              aria-label={showAllPartners ? "show more" : "show less"}
              className={`${styles.showMoreButton}`}
            >
              {showAllPartners ?
                <ExpandLessIcon />
                :
                <ExpandMoreIcon />
              }
            </IconButton>
          }
        </div>
      </div>
    </section>
  )
}

const GameProviderItem = ({ data, pageContext, location }) => {
  let gameProvider = data.sanityGameProvider;
  let headings = data.sanityGenericLandingPage.headingFilters;
  let games = data.allSanityGame.nodes;

  const { locale, alternateLocaleSlugs } = pageContext

  let { translations, bonuses } = useContext(ThemeContext)

  let title = gameProvider._rawHeaderText ? gameProvider._rawHeaderText : `${gameProvider.name} ${t.get(translations, 'game-provider-casinos-header')}`;
  let seoTitle = gameProvider.seoTitle ? gameProvider.seoTitle : `${gameProvider.name} ${t.get(translations, 'game-provider-casinos-seotitle')}`;

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location} bcDark>
      <SEO location={location} bonuses={bonuses} alternateSlug={alternateLocaleSlugs}
           title={seoTitle} gameProvider={gameProvider} description={gameProvider.seoMeta} />
      <ContentWrapper
        specialHeader={<Details
          translations={translations}
          locale={locale}
          gameProvider={gameProvider}
        />}
        title={!gameProvider._rawHeaderText && title}
        header={gameProvider._rawHeaderText && title}
        body={gameProvider._rawContent}
        footer={gameProvider._rawFooter}
      >
        <Content
          casinos={bonuses}
          translations={translations}
          locale={locale}
          filters={headings}
          gameProvider={gameProvider}
          games={games}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(GameProviderItem)
export const pageQuery = graphql`
  query GameProvidersQuery($providerName: String!) {
      sanitySilo (_id: {eq: "2a6640d6-d7c0-4fa1-915b-8dbdfc7b0570"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
     sanityGenericLandingPage(
      name: { eq: "Game Provider Landing" }
    ) {
      headingFilters {
        ...LocaleTranslation
      }
    }
    sanityGameProvider(
      name: { eq: $providerName }
    ) {
      seoMeta {
          ...LocaleString
      }
      seoTitle {
          ...LocaleString
      }
      name
      _rawContent(resolveReferences: {maxDepth: 15})
      _rawFooter(resolveReferences: {maxDepth: 15})
      _rawHeaderText(resolveReferences: {maxDepth: 15})
      largeIcon {
          asset {

              fluid {
                  ...GatsbySanityImageFluid
              }
          }
      }
      url
      headquarters {
          ...LocaleString
      }
      company
      socialGames
      vr
      mobile
      desktop
      keno
      poker
      slots
      blackJack
      craps
      roulette
      bingo
      baccarat
      videoPoker
      virtualSports
      liveGames
      progressiveJackpots
      others
      fixedOdds
      partners {
        largeIcon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        name
      }
    }
    allSanityGame(filter: {gameProvider: {name: {eq: $providerName}}}){
      nodes {
        name
        logoImage {
          asset {
            fluid {
                ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
